.blogContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// padding on the left and right
	padding: 0 10rem;

	.blogTitle {
		font-size: 2rem;
		font-weight: bold;
	}

	.blogContent {
		font-size: 1.5rem;
	}
}
