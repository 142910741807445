.midContainer {
	position: absolute;
	top: 1.6rem;
	left: 1rem;

	.window {
		position: absolute;
		z-index: 110;
	}

	.tooltipContainer {
		cursor: pointer;
		transition: all 0.3s;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		.tooltiptext {
			visibility: hidden;
			width: 120px;
			background-color: #afafaf;
			color: #fff;
			text-align: center;
			padding: 5px;
			border-radius: 6px;

			position: absolute;
			z-index: 1;
			bottom: 125%;
			left: 50%;
			margin-left: -60px;

			opacity: 0;
			transition: opacity 0.3s;

			&::after {
				content: '';
				position: absolute;
				top: 100%;
				left: 50%;
				margin-left: -5px;
				border-width: 5px;
				border-style: solid;
				border-color: #555 transparent transparent transparent;
			}
		}

		&:hover {
			width: 5rem;
			height: 5rem;
			.tooltiptext {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	.overlay {
		background-color: #3d09598c;
		position: fixed;
		display: block;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		backdrop-filter: blur(3px);
	}

	.promptWindow {
		border-radius: 10px;
		padding: 0.5rem;
		position: fixed;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		min-height: 80vh;
		min-width: 100vh;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;
		box-shadow: 0px 0px 18px 6px #a36180;

		.imageContainer {
			height: 50%;
			width: 50%;

			.upscaleButton {
				margin: 2rem;
				padding: 1rem;
			}

			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}

			h4 {
				text-align: center;
			}
		}

		.buttonsContainer {
			position: absolute;
			bottom: 5px;
			left: 50%;
			transform: translate(-50%, 0);
			display: flex;
		}

		.generateButton {
			color: #fff;
			border-color: #6c61a3;
			background-color: #6c61a3;
		}

		.previewImage {
			color: #6c61a3;
			border-color: #6c61a3;
			background-color: #fff;
		}

		.acceptButton {
			color: #fff;
			border-color: #c23939;
			background-color: #c23939;
		}
	}

	.promptInput {
		border: none;
		overflow: auto;
		outline: none;
		resize: none;
		border-radius: 5px;
		padding: 1rem;
		font-size: 1rem;
		margin: 2rem;
	}

	.closeIcon {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
		font-size: 1rem;
		font-weight: 800;
		color: #989898;
	}
}
